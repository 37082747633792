
.aa {
    color: #fff;
  
    
    display: inline-block;
  
    position: relative;
  }
  .aa:after {    
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: #fff;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }
  .aa:hover:after { 
    width: 100%; 
    left: 0; 
  }
  .navi{
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .navv{
    gap: 2rem;
  }
  .aa{
    color: white;
  }
  .ff{
    text-decoration: none !important;
    color: white;
    font-size: 20px;
  }
  ul li a {
    text-decoration: none;
    color: white;
}
.img98{
  width: 70%;
}

.nav{
    /* margin-top: 50px; */
}
.nonee{
    /* margin-top: 50px; */
}